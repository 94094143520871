*{
  box-sizing: border-box;
}

#fb-editor-hidden .sticky-controls {
  display: none;
}

.option-value.option-attr {
  display: none;
}